<template>
    <p v-if="terms" v-html="terms.content.replace(/\n/g, '<br>')"></p>
</template>

<script>
import api from "@/api";
export default {
    props: {
        code: { type: String, default: null }
    },
    data(){
        return {
            terms: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { terms } = await api.v1.terms.get({ code: this.code });
                this.terms = terms;
            }
            catch(error){
                console.error(error);
                alert(error.response.data.message);
            }
        },
    }
}
</script>
